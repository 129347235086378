import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../baseUrl";

// const baseQuery = fetchBaseQuery({
//   baseUrl: baseUrl,
//   credentials: "include",
//   keepUnusedDataFor: 5,
// });

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    getUserTransactions: builder.query({
      query: (details) =>
        details?.otp
          ? `/report/transaction/receipt?reference=${details?.reference}&otp=${
              details?.otp
            }&page=${details?.page || 1}&size=${details?.size || 100}`
          : `/report/transaction/receipt?reference=${details?.reference}`,
    }),
    getMerchantDetails: builder.query({
      query: (code) => `/merchant/business/public/${code}`,
    }),
  }),
});
